/* Data table styling */
.DataTable {
  width: 100%;
  min-width: 1000px;
  overflow-x: auto;
}

.headlinerCell {
  font-size: 18px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the text bold */
  white-space: normal;
  word-wrap: break-word;
}

.tableCell {
  font-size: 18px; /* Adjust the font size as needed */
  white-space: normal;
  word-wrap: break-word;
}

/* Add smooth transition effect for the row height */
.DataTable .MuiDataGrid-row {
  transition: all .5s ease-in-out;
}

.MuiDataGrid-cell:hover {
  color: #1976D2;
  background-color: #ffffffc4;
}
