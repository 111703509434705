/* ==========================
   Global CSS Variables
========================== */
:root {
  /* Primary Palette */
  --color-primary: #600000;
  --color-surface: #fffdfb;
  --color-background: #fff7e9;
  --size-max-width: 1140px;
}

/* ===========================
   General Reset and Body Styles
=========================== */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    background: var(--color-background);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  /* Smooth page transitions */
.ContentContainer {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  