/************************************
  Typography Styles
*************************************/

.headerTitle,
.headerSubtitle {
  color: var(--color-primary);
  font-family: 'Bungee', sans-serif;
  text-shadow:
    3px 3px 6px rgba(255, 255, 255, 0.9),
    0px 0px 6px rgb(255, 255, 255);
}

.headerTitle {
  padding-top: 50px;
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.headerSubtitle {
  font-size: 2rem;
  font-weight: 600;
  margin: 10px 0 0 0;
}

.headerContainer p {
  color: #000;
  font-family: 'Bungee', sans-serif;
  text-shadow:
    3px 3px 6px rgba(255, 255, 255, 0.9),
    0px 0px 6px rgb(255, 255, 255);
}

/************************************
  Main Container
*************************************/

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/************************************
  Top Bar
*************************************/

.topBar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--size-max-width);
  margin: 0 auto;
  /* Desktop: Left (drawer), Center (icons), Right (profile) */
}

/* Left: Drawer Button */
.drawerButtonWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
}

/* Center: Location & Date Icons */
.centerIcons {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.centerIcons p {
  white-space: nowrap;
  margin: 0;
}

/* Right: Profile / Verify Button */
.profileArea {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/************************************
  Responsive (Mobile) Layout
*************************************/
@media (max-width: 600px) {
  .headerTitle {
    font-size: 2rem;
    padding: 20px 1px 0;
  }

  .headerSubtitle {
    font-size: 1.5rem;
    padding: 5px 0 0 0;
  }

  /* Let the topBar wrap to new lines */
  .topBar {
    flex-wrap: wrap;
    justify-content: center; /* centers content horizontally */
  }

  /* Center Icons: Take up full width on the first row */
  .centerIcons {
    order: 1;
    width: 100%;
    gap: 5px;
  }
  .centerIcons p {
    font-size: 14px;
  }

  /* Drawer + Profile side by side on next row */
  .drawerButtonWrapper {
    order: 2;
    width: 50%;
    display: flex;
    justify-content: left;
  }

  .profileArea {
    order: 2;
    width: 50%;
    display: flex;
    justify-content: right;
  }
}
