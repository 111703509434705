/* ===========================
   General Reset and Body Styles
   =========================== */

/* Reset for html and body */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #fff7e9;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable scrolling */
}

/* ===========================
   Layout Containers
   =========================== */

/* Main application container */
.AppContainer {
  width: 100%;
  min-height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Keeps footer at the bottom */
}


/* ===========================
   Data Table Styles
   =========================== */

.AddCommentContainer {
  text-align: center;
  position: relative; /* Part of the normal flow */
}

.Footer {
  padding: 20px 0 10px 0; /* Padding for top and bottom */
  color: #000000;
  text-align: center;
  position: relative; /* Part of the normal flow */
}

@media (max-width: 600px) {
  .Footer {
    padding:0; /* Padding for top and bottom */
    color: #000000;
    text-align: center;
    position: relative; /* Part of the normal flow */
  }
}


