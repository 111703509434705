/* ===========================
   Comment Carousel Styles
=========================== */

.CommentCarousel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 10px;
}
