/* Flexbox container for buttons */
.add-button-container {
  display: flex;
  gap: 10px; /* Add some space between the buttons */
  padding-top: 10px;
  margin-left: -10px;
}

/* Common button styles */
.add-button, .delete-button {
  border-radius: 5px;
  width: 80px; 
  height: 56px; /* Match button height to the date pickers */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border: none;
  outline: none;
}

/* Add Button */
.add-button {
  font-size: 1.5rem !important;
  background-color: #244566 !important;
  color: #ffffff !important;
}

.add-button:hover {
  background-color: #1976D2 !important;
  color: white !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Delete Button */
.delete-button {
  background-color: #500000 !important;
  color: white !important;
}

.delete-button:hover {
  background-color: #991f1f !important;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Disabled Delete Button */
.delete-button:disabled {
  background-color: #A9A9A9 !important;
  color: #ffffff !important;
  cursor: not-allowed;
  box-shadow: none;
}

/* Edit Button */
.edit-button {
  background-color: #244566 !important;
  color: white !important;
}

.edit-button:hover {
  background-color: #1976D2 !important;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Disabled Edit Button */
.edit-button:disabled {
  background-color: #A9A9A9 !important;
  color: #ffffff !important;
  cursor: not-allowed;
  box-shadow: none;
}
