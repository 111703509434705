.CommentDisplay {
   background: var(--color-surface);
   border-radius: 10px;
   padding: 10px;
   width: 100%;
   height: 100%;
   box-sizing: border-box;
   text-align: left;
   position: relative;
   overflow: hidden;
   box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
 }
 
 .CommentDisplayImage {
   display: block;
   margin: auto;
   max-width: 100%;
   height: 75%;
   object-fit: contain;
   margin-bottom: 10px;
   border-radius: 10px;
 }
 
 .CommentDisplayAuthor {
   font-style: italic;
   font-size: 1em;
   font-weight: bold;
   color: #555;
 }
 
 .CommentDisplayDescription {
   font-size: 1.3em;
   white-space: pre-wrap;
   word-wrap: break-word;
   margin-top: 0px;
   margin-bottom: 0px;
 }
 