/* Modal styling */
.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: none;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    padding: 20px;
    border-radius: 10px;
  }

.modal-box h2 {
  margin-bottom: 10px;
}
