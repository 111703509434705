.CommentActions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #600000;
    padding: 10px 0 0;
    width: 100%;
}

.CommentButton {
    padding: 0.75rem 2rem;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 90%;
    text-align: center;
    margin: auto;
}

.CommentButton:hover {
    transform: scale(1.05);
}

.VerifyButton {
    background-color: #28a745;
    color: #fff;
}

.VerifyButton:hover {
    background-color: #218838;
}

.DeleteButton {
    background-color: #dc3545;
    color: #fff;
}

.DeleteButton:hover {
    background-color: #c82333;
}
