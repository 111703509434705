/* ==============================================
   Modal Overlay
============================================== */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* ==============================================
     Modal Content (Non-scrollable wrapper)
  ============================================== */
  .modal-content {
    position: relative; /* Needed for the close button's absolute positioning */
    background: #fff;
    width: 95vw;
    max-width: 800px;  /* (Optional) limit max width on large screens */
    border-radius: 8px;
    padding: 0px 0px 5px 0px;
    /* No scrolling here; the body inside will scroll instead. */
  }
  
  /* ==============================================
     Scrollable Body
  ============================================== */
  .modal-body {
    max-height: 75vh;   /* Takes up at most 75% of viewport height */
    overflow-y: auto;   /* This container scrolls if content is tall */
    padding: 10px;      /* Overall padding */
    box-sizing: border-box;
  }
  
  /* ==============================================
     Image Container
  ============================================== */
  .modal-image-container {
    width: 100%;
    height: auto;               /* Fixed container height (adjust if needed) */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-image {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the container if needed, but may crop */
    border-radius: 5px;
    background-color: #f1f1f1; /* Slight grey behind images, optional */
  }
  
  
  /* ==============================================
     Carousel Dots Position
  ============================================== */
  .my-carousel .control-dots {
    background-color: #3333338f;
    margin: 0px;
    border-radius: 5px;
  }
  
  .my-carousel .control-dots .dot {
    background: #000000;
    opacity: 0.8;
    box-shadow: none;
  }
  
  .my-carousel .control-dots .dot.selected {
    background: #ffffff;
    opacity: 1;
  }

  .my-carousel .slide {
    margin: auto; /* Adjust the 10px value to control the gap size */
  }
  
  /* ==============================================
     Text Content
  ============================================== */
  .modal-text-container {
    padding: 5px 0;
  }
  
  /* ==============================================
     Close Button (Stays bottom-right of the modal)
  ============================================== */
  .modal-close-button {
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: none;
    background-color: transparent;
    font-size: 26px;
    cursor: pointer;
    border-radius: 5px;
    color: #fff7e9;
    background-color: hsla(0, 0%, 0%, 0.623);
    padding: 4px 8px; 
  }
  