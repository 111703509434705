.ImagePadding {
    padding: 20px 0 15px;
}

.HeaderImage {
    width: 100%;
    max-height: 0; /* Start collapsed */
    opacity: 0; /* Start invisible */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px; /* Ensure the image itself has rounded corners */
    cursor: pointer;
    transition: max-height .7s ease-in-out, opacity .7s ease-in-out, transform .1s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transitions */
}

.HeaderImageVisible {
    max-height: 1000px; /* Expand to full height */
    opacity: 1; /* Fully visible */
}

.HeaderImage:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.562);
    border-radius: 15px; /* Ensure the rounded corners remain on hover */
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.OverlayVisible {
    opacity: 1;
    visibility: visible;
}

.OverlayContainer {
    padding: 20px;
    overflow-y: scroll; /* Allows scrolling without showing the scrollbar */
    scrollbar-width: none; /* For Firefox */
}

.OverlayContainer::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
}

.Overlay p {
    font-size: 1.7rem;
    margin: 3px;
    text-align: left;
    color: rgb(255, 255, 255);
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
    font-family: 'PT Serif', serif;
    font-weight: 500;
}

.ImageRow {
    display: flex;
    justify-content: space-between; /* Distributes space evenly between images */
    width: 100%; /* Ensures the row takes the full width of the container */
    margin-bottom: 20px; /* Adds space below the image row */
}

.OverlayImage {
    width: calc(33.33% - 10px); /* Adjust width to account for margin */
    object-fit: cover; /* Ensures images cover their area without distortion */
    margin-right: 10px; /* Adds space to the right of each image */
    transition: transform 0.3s ease; /* Smooth transition for scaling */
    border-radius: 10px;
}

.OverlayImage:last-child {
    margin-right: 0; /* Removes margin from the last image */
}

.OverlayImage:hover {
    transform: scale(1.05); /* Scales the image up on hover */
    box-shadow: 0 0px 25px rgba(255, 255, 255, 0.6);
}

@media (max-width: 768px) {

    .ImageContainer.OverlayVisible {
        height: 500px; /* Maintain height when overlay is visible */
    }

    .ImagePadding {
        padding-bottom: 10px;
    }

    .OverlayContainer {
        padding: 0;
        padding-top: 15px;
        overflow-y: scroll; /* Allows scrolling without showing the scrollbar */
        scrollbar-width: none; /* For Firefox */
    }
    .OverlayImage {
        width: calc(33.33%); /* Adjust width to account for margin */
        margin: 0;
        object-fit: cover; /* Ensures images cover their area without distortion */
        transition: transform 0.3s ease; /* Smooth transition for scaling */
        border-radius: 10px;
    }

    .Overlay p {
        font-size: 1.3rem;
        margin: 3px;
        text-align: left;
        color: rgb(255, 255, 255);
        text-shadow: 0px 0px 10px rgb(0, 0, 0);
        font-family: 'PT Serif', serif;
        font-weight: 500;
    }

    .Overlay {
        background-color: rgb(0, 0, 0);
    }
}


